import './assets/css/App.css';
import {CustomRouter} from "./components/routing/router";
import {Provider} from "react-redux";
import {store} from "./store/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';


function App() {
  return (
      <Provider store={store}>
        <CustomRouter/>
      </Provider>
  );
}

export default App;
