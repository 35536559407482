import Container from "react-bootstrap/Container";
import {Button, Col, Form, Image, InputGroup, Row} from "react-bootstrap";
import {Panel} from "primereact/panel";
import vigenereCypher from "../../../assets/images/vigenere_cypher.jpg";
import {useState} from "react";

export function encrypt(message, key) {
    let retString = ""; // init encrypted string
    let j = 0; // index key

    // goes through all the chars in the message, and encodes them to fit within the 32-126 ASCII values
    for (let charIndex = 0; charIndex < message.length; charIndex++) {
        if (j === key.length) {
            // resets the key index if the key is much shorter than the original message
            j = 0;
        }

        // converting chars to their character codes (Unicode code units)
        let plainNum = message.charCodeAt(charIndex) - 32;
        let keyNum = key.charCodeAt(j) - 32;

        let encryptChar = (plainNum + keyNum) % 94; // vigenere encryption formula

        retString += String.fromCharCode(encryptChar + 32);

        // increments the key index
        j++;
    }
    return retString;
}

export function decrypt(message, key) {
    let retString = ""; // init decrypted string
    let j = 0; // index key

    // goes through all the chars in the message, and decodes them to return the original message
    for (let charIndex = 0; charIndex < message.length; charIndex++) {
        if (j === key.length) {
            // resetting the key index
            j = 0;
        }

        // converting chars to their character codes (Unicode code units)
        let plainNum = message.charCodeAt(charIndex) - 32;
        let keyNum = key.charCodeAt(j) - 32;

        let encryptChar = (plainNum - keyNum + 94) % 94; // vigenere decryption formula
        retString += String.fromCharCode(encryptChar + 32);

        // increments the key index
        j++;
    }

    return retString;
}

export default function VigenereTab(){
    const [keyPhrase, setKeyphrase] = useState("");
    const [plainText, setPlainText] = useState("");
    const [resultText, setResultText] = useState("");
    const [encryptMode, setEncryptMode] = useState(true);

    function generateEncryption (){
        setResultText(encryptMode ? encrypt(plainText, keyPhrase) : decrypt(plainText, keyPhrase))
    }
    
    return(
        <Container>
            <Row>
                <Col lg={6}>
                    <Panel header="Description">
                        <p>
                            Advanced vigenère cypher for simple encryption and decryption of
                            plaintext with a key.
                        </p>
                        <p>
                            While this algorithm does not provide maximal security, it can make
                            encrypted text difficult to decrypt. The matrix used to decrypt
                            makes it cumbersome to decrypt.
                        </p>
                    </Panel>
                    <br/>
                    <Panel header="How It Works">
                        <Image fluid style={{width: "100%", height: "auto"}} src={vigenereCypher}/>
                        <p style={{paddingTop: '20px'}}>
                            Instead of using a 26x26 grid, I used a grid of 94 x 94. My implementation
                            includes characters [a-z][A-Z][0-9] and punctuation.
                        </p>
                    </Panel>
                </Col>
                <Col lg={6}>
                    <Panel header="Demonstration">
                        <p>
                            The following is a demonstration of the vigenère functionality,
                            powered by javascript.
                        </p>

                        <br/>

                        <Form>
                            <Row>
                                <InputGroup>
                                    <Form.Control placeholder={"Key Phrase"} value={keyPhrase} onChange={(e) => {setKeyphrase(e.target.value)}}/>
                                    <Form.Control placeholder={"Message"} value={plainText} onChange={(e) => {setPlainText(e.target.value)}}/>
                                    <InputGroup.Checkbox defaultChecked={encryptMode} onClick={()=> {setEncryptMode(!encryptMode)}}/>
                                    <Button variant="info" onClick={()=> {generateEncryption()}}>{encryptMode ? "Encrypt" : "Decrypt"}</Button>
                                </InputGroup>
                            </Row>
                            <br/>
                            <Row>
                                  <Form.Group>
                                      <InputGroup.Text>Result</InputGroup.Text>
                                      <Form.Control as="textarea" placeholder={resultText} readOnly/>
                                  </Form.Group>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Button variant={"info"} disabled={resultText.length === 0} onClick={()=> {
                                        navigator.clipboard.writeText(resultText).then(() => {})}}>
                                        COPY TO CLIPBOARD
                                    </Button>
                                </Col>
                                <Col>
                                    <Button variant={"warning"} onClick={()=> {
                                        setPlainText("");
                                        setResultText("");}}>RESET</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Panel>
                    <br/>
                    <Panel header={"More Information"}>
                        <p>To learn more about this project, check it out on <a href="https://github.com/tsbudd/vigenereCypher"
                                                                        target="_blank">GitHub</a>.</p>
                    </Panel>
                </Col>
            </Row>
        </Container>
    )
}