import Container from "react-bootstrap/Container";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Panel} from "primereact/panel";
import {useState} from "react";
import {Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        padding: 20,
    },
    title: {
        fontSize: 20,
        marginBottom: 20,
        marginTop: 10,
    },
    actHeader: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 5,
    },
    header: {
        fontSize: 14,
        marginBottom: 5
    },
    content: {
        fontSize: 12,
        marginBottom: 50
    },
});

export default function IdeaToStorylineTab(){
    const [projectName, setProjectName] = useState("");
    const [phase1_0, setPhase1_0] = useState("");
    const [phase2_0, setPhase2_0] = useState("");
    const [phase2_1, setPhase2_1] = useState("");
    const [phase2_2, setPhase2_2] = useState("");
    const [act1_0, setAct1_0] = useState("");
    const [act1_1, setAct1_1] = useState("");
    const [act1_2, setAct1_2] = useState("");
    const [act2a_0, setAct2a_0] = useState("");
    const [act2a_1, setAct2a_1] = useState("");
    const [act2a_2, setAct2a_2] = useState("");
    const [act2a_3, setAct2a_3] = useState("");
    const [act2a_4, setAct2a_4] = useState("");
    const [act2b_0, setAct2b_0] = useState("");
    const [act2b_1, setAct2b_1] = useState("");
    const [act2b_2, setAct2b_2] = useState("");
    const [act2b_3, setAct2b_3] = useState("");
    const [act2b_4, setAct2b_4] = useState("");
    const [act2b_5, setAct2b_5] = useState("");
    const [act3_0, setAct3_0] = useState("");
    const [act3_1, setAct3_1] = useState("");
    const [act3_2, setAct3_2] = useState("");

    function generatePhase2Inputs(){
        let retArr = [];

        const phase2a = {};
        phase2a.header = "What is Their Desire?";
        phase2a.desc = "(the thing they want + think will make them  happy)"
        phase2a.placeHolder = phase2_0;
        phase2a.onchange = (e) =>{setPhase2_0(e.target.value)};

        const phase2b = {};
        phase2b.header = "What is Their Fear?";
        phase2b.desc = "(the thing stopping them from going after the thing that wll make them happy)"
        phase2b.placeHolder = phase2_1;
        phase2b.onchange = (e) =>{setPhase2_1(e.target.value)};

        const phase2c = {};
        phase2c.header = "What is Their Misbelief?";
        phase2c.desc = "(the thing they mistakenly believe is true about he world, which feeds off their fear)"
        phase2c.placeHolder = phase2_2;
        phase2c.onchange = (e) =>{setPhase2_2(e.target.value)};

        retArr.push(phase2a);
        retArr.push(phase2b);
        retArr.push(phase2c);

        return retArr;
    }

    function generateAct1Inputs() {
        let retArr = [];

        const act1a = {};
        act1a.header = "The Hook";
        act1a.desc = "grab readers with the inner conflict of the Protagonist"
        act1a.placeHolder = act1_0;
        act1a.onchange = (e) =>{setAct1_0(e.target.value)};

        const act1b = {};
        act1b.header = "Setup";
        act1b.desc = "something is about to happen to Protagonist, reader can feel it"
        act1b.placeHolder = act1_1;
        act1b.onchange = (e) =>{setAct1_1(e.target.value)};

        const act1c = {};
        act1c.header = "Inciting Incident";
        act1c.desc = "something throws Protagonist outside their comfort zone"
        act1c.placeHolder = act1_2;
        act1c.onchange = (e) =>{setAct1_2(e.target.value)};

        retArr.push(act1a);
        retArr.push(act1b);
        retArr.push(act1c);

        return retArr;
    }

    function generateAct2aInputs() {
        let retArr = [];

        const act2a0 = {};
        act2a0.header = "Build-Up";
        act2a0.desc = "Protagonist is going to have to face this thing head on"
        act2a0.placeHolder = act2a_0;
        act2a0.onchange = (e) =>{setAct2a_0(e.target.value)};

        const act2a1 = {};
        act2a1.header = "1st Plot Point";
        act2a1.desc = "Protagonist makes a decision that determines what happens next"
        act2a1.placeHolder = act2a_1;
        act2a1.onchange = (e) =>{setAct2a_1(e.target.value)};

        const act2a2 = {};
        act2a2.header = "1st Pinch Point";
        act2a2.desc = "the opposition/antagonistic force looms in the distance"
        act2a2.placeHolder = act2a_2;
        act2a2.onchange = (e) =>{setAct2a_2(e.target.value)};

        const act2a3 = {};
        act2a3.header = "Pre-Midpoint Reactionary Hero";
        act2a3.desc = "Protagonist is in pursuit of their goal, but something is going to stand in their way"
        act2a3.placeHolder = act2a_3;
        act2a3.onchange = (e) =>{setAct2a_3(e.target.value)};

        const act2a4 = {};
        act2a4.header = "Midpoint";
        act2a4.desc = "plot twist! everything changes"
        act2a4.placeHolder = act2a_4;
        act2a4.onchange = (e) =>{setAct2a_4(e.target.value)};

        retArr.push(act2a0);
        retArr.push(act2a1);
        retArr.push(act2a2);
        retArr.push(act2a3);
        retArr.push(act2a4);

        return retArr;
    }

    function generateAct2bInputs() {
        let retArr = [];

        const act2b0 = {};
        act2b0.header = "Post-Midpoint Action Hero";
        act2b0.desc = "thanks to the game-changing midpoint, Protagonist has to switch gears when it comes to their goal"
        act2b0.placeHolder = act2b_0;
        act2b0.onchange = (e) =>{setAct2b_0(e.target.value)};

        const act2b1 = {};
        act2b1.header = "2nd Pinch Point";
        act2b1.desc = "Antagonist gets closer to disrupting Protagonist's life"
        act2b1.placeHolder = act2b_1;
        act2b1.onchange = (e) =>{setAct2b_1(e.target.value)};

        const act2b2 = {};
        act2b2.header = "Supposed Victory";
        act2b2.desc = "Protagonist feels confident they will be victorious...little do they know, disaster is on the way"
        act2b2.placeHolder = act2b_2;
        act2b2.onchange = (e) =>{setAct2b_2(e.target.value)};

        const act2b3 = {};
        act2b3.header = "Disaster";
        act2b3.desc = "Something (or everything) goes wrong. reader saw it coming. Protagonist did not."
        act2b3.placeHolder = act2b_3;
        act2b3.onchange = (e) =>{setAct2b_3(e.target.value)};

        const act2b4 = {};
        act2b4.header = "Dark Moment";
        act2b4.desc = "Protagonist feels lost and hopeless in wake of the disaster (their fear challenges them again)"
        act2b4.placeHolder = act2b_4;
        act2b4.onchange = (e) =>{setAct2b_4(e.target.value)};

        const act2b5 = {};
        act2b5.header = "Recovery";
        act2b5.desc = "Protagonist must overcome their false beliefs to continue to the climax, thus developing as a character"
        act2b5.placeHolder = act2b_5;
        act2b5.onchange = (e) =>{setAct2b_5(e.target.value)};

        retArr.push(act2b0);
        retArr.push(act2b1);
        retArr.push(act2b2);
        retArr.push(act2b3);
        retArr.push(act2b4);
        retArr.push(act2b5);

        return retArr;
    }

    function generateAct3Inputs() {
        let retArr = [];

        const act3a = {};
        act3a.header = "Climactic Confrontation";
        act3a.desc = "Protagonist faces their biggest challenge of all"
        act3a.placeHolder = act3_0;
        act3a.onchange = (e) =>{setAct3_0(e.target.value)};

        const act3b = {};
        act3b.header = "Victory";
        act3b.desc = "Protagonist overcomes"
        act3b.placeHolder = act3_1;
        act3b.onchange = (e) =>{setAct3_1(e.target.value)};

        const act3c = {};
        act3c.header = "Resolution/End";
        act3c.desc = "loose ends are wrapped up, reader is satisfied"
        act3c.placeHolder = act3_2;
        act3c.onchange = (e) =>{setAct3_2(e.target.value)};

        retArr.push(act3a);
        retArr.push(act3b);
        retArr.push(act3c);

        return retArr;
    }

    const createPdf = () => (
        <Document>
            <Page style={styles.page}>
                <View>
                    <Text style={styles.title}>Phase I: The Premise</Text>
                    <Text style={styles.header}>What is the basic idea?</Text>
                    <Text style={styles.content}>{phase1_0}</Text>
                </View>
                <View>
                    <Text style={styles.title}>Phase II: Meet Your Protagonist</Text>
                    {phase2Inputs.map((item, index) => (
                        <div key={"phaseII"+index}>
                            <Text style={styles.header}>{item.header}</Text>
                            <Text style={styles.content}>{item.placeHolder}</Text>
                        </div>
                    ))}

                </View>
                <View>
                    <Text style={styles.title}>Phase III: The 3-Act Structure</Text>

                    <Text style={styles.actHeader}>ACT I</Text>
                    {act1Inputs.map((item, index) => (
                        <div key={"phaseII"+index}>
                            <Text style={styles.header}>{item.header}</Text>
                            <Text style={styles.content}>{item.placeHolder}</Text>
                        </div>
                    ))}

                    <Text style={styles.actHeader}>ACT IIa</Text>
                    {act2aInputs.map((item, index) => (
                        <div key={"phaseII"+index}>
                            <Text style={styles.header}>{item.header}</Text>
                            <Text style={styles.content}>{item.placeHolder}</Text>
                        </div>
                    ))}

                    <Text style={styles.actHeader}>ACT IIb</Text>
                    {act2bInputs.map((item, index) => (
                        <div key={"phaseII"+index}>
                            <Text style={styles.header}>{item.header}</Text>
                            <Text style={styles.content}>{item.placeHolder}</Text>
                        </div>
                    ))}

                    <Text style={styles.actHeader}>ACT III</Text>
                    {act3Inputs.map((item, index) => (
                        <div key={"phaseII"+index}>
                            <Text style={styles.header}>{item.header}</Text>
                            <Text style={styles.content}>{item.placeHolder}</Text>
                        </div>
                    ))}
                </View>
            </Page>
        </Document>
    )

    function createExportJson() {
        let json = {};

        // phase 1
        json.phase1_0 = {};
        json.phase1_0.header = "What is the Basic Idea?";
        json.phase1_0.content = phase1_0;

        // phase 2
        phase2Inputs.forEach((item, index) => {
            const key = "phase2_" + index;
            json[key] = {};
            json[key].header = item.header;
            json[key].content = item.placeHolder;
        })

        // act I
        act1Inputs.forEach((item, index) => {
            const key = "act1_" + index;
            json[key] = {};
            json[key].header = item.header;
            json[key].content = item.placeHolder;
        })

        // act IIa
        act2aInputs.forEach((item, index) => {
            const key = "act2a_" + index;
            json[key] = {};
            json[key].header = item.header;
            json[key].content = item.placeHolder;
        })

        // act IIb
        act2bInputs.forEach((item, index) => {
            const key = "act2b_" + index;
            json[key] = {};
            json[key].header = item.header;
            json[key].content = item.placeHolder;
        })

        // act III
        act3Inputs.forEach((item, index) => {
            const key = "act3_" + index;
            json[key] = {};
            json[key].header = item.header;
            json[key].content = item.placeHolder;
        })

        return json;
    }

    const downloadJson = () => {
        const jsonBlob = new Blob([JSON.stringify(createExportJson(), null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(jsonBlob);

        const link = document.createElement('a');
        link.href = url;
        link.download = projectName !== "" ? projectName + "_idea_to_storyline": "idea_to_storyline";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    function importJson(jsonData){
        for (const key in jsonData){
            let method = "set" + key[0].toUpperCase() + key.substring(1);
            const value = jsonData[key].content;
            const setCall = `${method}("${value}")`;
            try{
                eval(setCall)
            }catch (e) {

            }
        }
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0]; // Get the selected file
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result); // Parse JSON data
                importJson(jsonData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        };

        reader.readAsText(file); // Read the file as text
    };

    const phase2Inputs = generatePhase2Inputs();
    const act1Inputs = generateAct1Inputs();
    const act2aInputs = generateAct2aInputs();
    const act2bInputs = generateAct2bInputs();
    const act3Inputs = generateAct3Inputs();


    return(
        <Container>
            <Panel header="Phase I: The Premise" toggleable>
                <h5>What is the basic idea?</h5>
                <Form>
                    <Form.Group>
                        <Form.Control as="textarea" value={phase1_0} onChange={(e) =>{setPhase1_0(e.target.value)}}/>
                    </Form.Group>
                </Form>
            </Panel>
            <br/>
            <Panel header="Phase II: Meet Your Protagonist" toggleable>
                {phase2Inputs.map((item, index) => (
                    <div key={index+"phase2"}>
                        <h5>{item.header}</h5>
                        <p><i>{item.desc}</i></p>
                        <Form>
                            <Form.Group>
                                <Form.Control as="textarea" value={item.placeHolder} onChange={item.onchange}/>
                            </Form.Group>
                        </Form>
                        <br/>
                    </div>
                ))}
            </Panel>
            <br/>
            <Panel header="Phase III: The 3-Act Structure" toggleable>
                <h3><b>Act I:</b></h3>

                {act1Inputs.map((item, index) => (
                    <div key={index+"phase1"}>
                        <h5>{item.header}</h5>
                        <p><i>{item.desc}</i></p>
                        <Form>
                            <Form.Group>
                                <Form.Control as="textarea" value={item.placeHolder} onChange={item.onchange}/>
                            </Form.Group>
                        </Form>
                        <br/>
                    </div>
                ))}
                <br/>

                <h3><b>Act IIa:</b></h3>

                {act2aInputs.map((item, index) => (
                    <div key={index+"phase2a"}>
                        <h5>{item.header}</h5>
                        <p><i>{item.desc}</i></p>
                        <Form>
                            <Form.Group>
                                <Form.Control as="textarea" value={item.placeHolder} onChange={item.onchange}/>
                            </Form.Group>
                        </Form>
                        <br/>
                    </div>
                ))}
                <br/>

                <h3><b>Act IIb:</b></h3>

                {act2bInputs.map((item, index) => (
                    <div key={index+"phase2b"}>
                        <h5>{item.header}</h5>
                        <p><i>{item.desc}</i></p>
                        <Form>
                            <Form.Group>
                                <Form.Control as="textarea" value={item.placeHolder} onChange={item.onchange}/>
                            </Form.Group>
                        </Form>
                        <br/>
                    </div>
                ))}
                <br/>

                <h3><b>Act III:</b></h3>

                {act3Inputs.map((item, index) => (
                    <div key={index+"phase2c"}>
                        <h5>{item.header}</h5>
                        <p><i>{item.desc}</i></p>
                        <Form>
                            <Form.Group>
                                <Form.Control as="textarea" value={item.placeHolder} onChange={item.onchange}/>
                            </Form.Group>
                        </Form>
                        <br/>
                    </div>
                ))}
            </Panel>
            <br/>

            <Panel header={"Export"}>
                <Form>
                    <Row>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Export</Form.Label>
                                <InputGroup>
                                    <Form.Control placeholder={"Project Name"} onChange={(e)=>{setProjectName(e.target.value)}}/>
                                    <Button variant="warning" onClick={downloadJson}>Export JSON</Button>
                                    <Button variant="outline-info">
                                        <PDFDownloadLink
                                            document={createPdf()}
                                            fileName={projectName !== "" ? projectName + "_idea_to_storyline.pdf" : "ideaToStoryline.pdf"}>
                                            Download PDF
                                        </PDFDownloadLink>
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Import</Form.Label>
                                <Form.Control type="file" accept=".json" onChange={handleFileUpload}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Panel>
        </Container>
    )
}