import Container from "react-bootstrap/Container";
import {Button, Modal, Row} from "react-bootstrap";
import {Panel} from "primereact/panel";
import React, {useState} from "react";
import ennegramTypes from "../../../assets/pdf/ennegramTypes.pdf";
import threeActStructure from "../../../assets/pdf/threeActStoryStructure.pdf";

export default function PdfResources(){
    const [showStoryStructureDoc, setShowStoryStructureDoc] = useState(false);
    const [showEnnegramDoc, setShowEnnegramDoc] = useState(false);

    return(
        <Container>
            <Panel header={"Additional Resources"}>
                <Row>
                    <Button variant="success" onClick={() => {setShowStoryStructureDoc(true)}}>
                        Three Act Story Structure
                    </Button>

                    <Modal show={showStoryStructureDoc} onHide={() => {setShowStoryStructureDoc(false)}} size={"xl"}>
                        <Modal.Header closeButton>
                            <Modal.Title>Three Act Story Structure</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <iframe
                                src={threeActStructure}
                                style={{ width: '100%', height: '80vh'}}
                            ></iframe>
                        </Modal.Body>
                    </Modal>
                </Row>

                <br/>

                <Row>
                    <Button variant="success" onClick={() => {setShowEnnegramDoc(true)}}>
                        Ennegram Types
                    </Button>

                    <Modal show={showEnnegramDoc} onHide={() => {setShowEnnegramDoc(false)}} size={"xl"}>
                        <Modal.Header closeButton>
                            <Modal.Title>Ennegram Types</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <iframe
                                src={ennegramTypes}
                                style={{ width: '100%', height: '80vh'}}
                            ></iframe>
                        </Modal.Body>
                    </Modal>
                </Row>


            </Panel>
        </Container>
    )
}