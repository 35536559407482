import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

function TopNavbar() {
    return (
        <Navbar className="App-navbar">
            <Container>
                <Navbar.Brand>
                    <Link to="/" className='App-logo-custom'>TSBudd</Link>
                </Navbar.Brand>
                <Navbar.Collapse>
                    <Nav className="navbar-links">
                        <Link to="/education" className="nav-link">Education</Link>
                        <Link to="/projects" className="nav-link">Projects</Link>
                        <Link to="/contact" className="nav-link">Contact</Link>
                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
}

export default TopNavbar;
