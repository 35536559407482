import {useDispatch, useSelector} from "react-redux";
import {fetchTemplateData} from "../../store/slices/templateSlice";
import {useEffect} from "react";
import TopNavbar from "../widgets/navBar";
import me_breakers from "../../assets/images/me_breakers.jpg"
import Container from "react-bootstrap/Container";
import {Card, Row} from "react-bootstrap";
import {Helmet} from "react-helmet";

function Home() {
    const dispatch = useDispatch();
    const fact = useSelector((state) => state.data.fact.data);

    useEffect(() => {
        dispatch(fetchTemplateData())
    }, [dispatch]);

    return (
        <div className="App" style={{backgroundImage: `url(${me_breakers})`}}>
            <Helmet>
                <title>TSBudd.com | Home</title>
            </Helmet>
            <div className="App-navbar">
                <TopNavbar/>
            </div>

            <Container className="App-body">

                <Row style={{paddingTop: "30rem"}}/>

                <Row>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(0,0,0,0.4)", color: "ghostwhite"}}>
                        <Card.Body>
                            <h1>Welcome!</h1>
                            <hr className="divider my-4" />
                            <h4>My name is Tyler S. Budd</h4>
                        </Card.Body>
                    </Card>
                </Row>

                <Row style={{paddingTop: "3rem"}}>
                    <Card style={{borderRadius: "15px", backgroundColor: "rgba(0,0,0,0.5)", color: "ghostwhite"}}>
                        <Card.Body>
                            <h5><p><u>Here's A Random Fact!</u></p></h5>
                            <p>{fact}</p>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </div>
    );
}

export default Home;