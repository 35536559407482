import Container from "react-bootstrap/Container";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Panel} from "primereact/panel";
import {useState} from "react";
import {Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        padding: 20,
    },
    title: {
        fontSize: 20,
        marginBottom: 20,
        marginTop: 10,
    },
    header: {
        fontSize: 16,
        marginBottom: 5
    },
    subHeader: {
        fontSize: 14,
        marginBottom: 10,
        marginTop: 5,
    },
    content: {
        fontSize: 12,
        marginBottom: 50
    },
});

export default function CharacterMisbeliefTab(){
    const [characterName, setCharacterName] = useState("");
    const [step1, setStep1] = useState("");
    const [step2, setStep2] = useState("");
    const [step3, setStep3] = useState("");
    const [step4, setStep4] = useState("");
    const [step5a, setStep5a] = useState("");
    const [step5b, setStep5b] = useState("");
    const [step5c, setStep5c] = useState("");
    const [step6, setStep6] = useState("");
    const [step7, setStep7] = useState("");
    const [step8, setStep8] = useState("");
    const [step9a, setStep9a] = useState("");
    const [step9b, setStep9b] = useState("");
    const [step9c, setStep9c] = useState("");
    const inputs = generateInputs();


    function generateInputs(){
        let retArr = [];

        const step_1 = {};
        step_1.header = "What Is Your Main Theme?";
        step_1.desc = "in as few words as possible, describe your story's main \"truth\", character lesson, or \"big idea\""
        step_1.placeHolder = step1;
        step_1.onchange = (e) =>{setStep1(e.target.value)};

        const step_2 = {};
        step_2.header = "Flip This Truth Upside-Down And Make It A Lie";
        step_2.desc = "what is the exact opposite of your story's truth? this is the clearest definition of your character's misbelief"
        step_2.placeHolder = step2;
        step_2.onchange = (e) =>{setStep2(e.target.value)};

        const step_3 = {};
        step_3.header = "Ask Yourself: \"What Could Have Happened In My Character's Past To Make Them Believe Such a Thing?\"";
        step_3.desc = "remember: it doesn't have to be a big pivotal moment - it could be a subtle but constant affirmation (the drip on the rock)"
        step_3.placeHolder = step3;
        step_3.onchange = (e) =>{setStep3(e.target.value)};

        const step_4 = {};
        step_4.header = "Write That Backstory Scene For Your Character";
        step_4.desc = "actually writing this backstory scene will give you insight into the depths of your character's inner conflict"
        step_4.placeHolder = step4;
        step_4.onchange = (e) =>{setStep4(e.target.value)};

        const step_5 = {};
        step_5.header = "Describe (Or Write) Other Scenes In The Character's Past That Cemented This Misbelief As Truth To Them";
        step_5.desc = "brainstorm a few other moments throughout your character's past where something challenged their " +
            "hard won misbelief and they chose their lie in response"
        step_5.subs = [];
        const sub5a = {};
        sub5a.header = "Scene 1";
        sub5a.placeHolder = step5a;
        sub5a.onchange = (e) =>{setStep5a(e.target.value)};
        const sub5b = {};
        sub5b.header = "Scene 2";
        sub5b.placeHolder = step5b;
        sub5b.onchange = (e) =>{setStep5b(e.target.value)};
        const sub5c = {};
        sub5c.header = "Scene 3";
        sub5c.placeHolder = step5c;
        sub5c.onchange = (e) =>{setStep5c(e.target.value)};
        step_5.subs.push(sub5a);
        step_5.subs.push(sub5b);
        step_5.subs.push(sub5c);


        const step_6 = {};
        step_6.header = "Ask Yourself: \"How Is My Character Dissatisfied With Their Life Today, Because They Believe This Lie So Firmly?\"";
        step_6.desc = "their misbelief is the root of their dissatisfaction - but they don't know that. all they know is they are dissatisfied"
        step_6.placeHolder = step6;
        step_6.onchange = (e) =>{setStep6(e.target.value)};

        const step_7 = {};
        step_7.header = "Ask Yourself: \"What Does My Character Think Will Make Them Happy, Based On Their Misbelief?\"";
        step_7.desc = "at the end of the day, all your character wants is to be happy, but their definition of \"happiness\" " +
            "is contorted by their misbelief"
        step_7.placeHolder = step7;
        step_7.onchange = (e) =>{setStep7(e.target.value)};

        const step_8 = {};
        step_8.header = "Ask Yourself: \"What Steps Could My Character Take Now To Actively Pursue This Goal?\"";
        step_8.desc = "this will lead into your plot (the external conflict) and become your character's mission throughout the story"
        step_8.placeHolder = step8;
        step_8.onchange = (e) =>{setStep8(e.target.value)};

        const step_9 = {};
        step_9.header = "Clarify The Motive, The Goal, And The Plot";
        step_9.desc = "go back through your answers to all these questions and pull out 1) the lie they started believing at a young age, " +
            "2) what they think will bring them true happiness, and 3) the steps they're going to take to make this dream a reality"
        step_9.subs = [];
        const sub9a = {};
        sub9a.header = "The Motive";
        sub9a.placeHolder = step9a;
        sub9a.onchange = (e) =>{setStep9a(e.target.value)};
        const sub9b = {};
        sub9b.header = "The Goal";
        sub9b.placeHolder = step9b;
        sub9b.onchange = (e) =>{setStep9b(e.target.value)};
        const sub9c = {};
        sub9c.header = "The Plot";
        sub9c.placeHolder = step9c;
        sub9c.onchange = (e) =>{setStep9c(e.target.value)};
        step_9.subs.push(sub9a);
        step_9.subs.push(sub9b);
        step_9.subs.push(sub9c);


        retArr.push(step_1);
        retArr.push(step_2);
        retArr.push(step_3);
        retArr.push(step_4);
        retArr.push(step_5);
        retArr.push(step_6);
        retArr.push(step_7);
        retArr.push(step_8);
        retArr.push(step_9);

        return retArr;
    }

    const createPdf = () => (
        <Document>
            <Page style={styles.page}>
                <View>
                    <Text style={styles.title}>Character Misbelief Worksheet - {characterName}</Text>
                    {inputs.map((item, index) => (
                        <div key={"phaseII"+index}>
                            <Text style={styles.header}>{item.header}</Text>

                            {item.placeHolder !== undefined && <Text style={styles.content}>{item.placeHolder}</Text>}

                            {item.subs !== undefined && <div>
                                {item.subs.map((subItem, subIndex) => (
                                    <div key={subIndex+subItem.header}>
                                        <Text style={styles.subHeader}>{subItem.header}</Text>
                                        <Text style={styles.content}>{subItem.placeHolder}</Text>
                                    </div>
                                ))}
                            </div>}
                        </div>
                    ))}
                </View>
            </Page>
        </Document>
    )

    function createExportJson() {
        let json = {};

        inputs.forEach((item, index) => {
            const key = "step"+(index+1);
            json[key] = {};
            json[key].header = item.header;

            if (item.placeHolder !== undefined) json[key].content = item.placeHolder
            if (item.subs !== undefined){
                let content = {};

                item.subs.forEach((subItem, subIndex) => {
                    const subItemKey = "step" + (index+1) + String.fromCharCode(97 + subIndex)
                    content[subItemKey] = {};
                    content[subItemKey].header = subItem.header;
                    content[subItemKey].content = subItem.placeHolder;
                })

                json[key].content = content;
            }
        })

        return json;
    }

    const downloadJson = () => {
        const jsonBlob = new Blob([JSON.stringify(createExportJson(), null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(jsonBlob);

        const link = document.createElement('a');
        link.href = url;
        link.download = characterName !== "" ? characterName + "_characterMisbelief": "characterMisbelief";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    function importJson(jsonData){
        for (const key in jsonData){
            let method = "set" + key[0].toUpperCase() + key.substring(1);
            let value = jsonData[key].content;

            // handle subjson structures
            if (typeof value === "string"){
                const setCall = `${method}("${value}")`;
                try{
                    eval(setCall)
                }catch (e) {
                }
            }else{
                let count = 0;
                for (const subKey in value){
                    method = "set" + key[0].toUpperCase() + key.substring(1) + String.fromCharCode(97 + count);
                    value = jsonData[key].content[subKey].content;

                    const setCall = `${method}("${value}")`;
                    try{
                        eval(setCall)
                    }catch (e) {
                    }
                    count++;
                }
            }
        }
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0]; // Get the selected file
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result); // Parse JSON data
                importJson(jsonData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        };

        reader.readAsText(file); // Read the file as text
    };


    return(
        <Container>
            {inputs.map((item, index) => (
                <div key={index+"characterMisbelief"}>
                    <Panel header={"Step " +(index+1)}>
                        <h5>{item.header}</h5>
                        <p><i>{item.desc}</i></p>
                        {item.placeHolder !== undefined && <Form>
                            <Form.Group>
                                <Form.Control as="textarea" value={item.placeHolder} onChange={item.onchange}/>
                            </Form.Group>
                        </Form>}

                        {item.subs !== undefined && <div>
                            {item.subs.map((subItem, subIndex) => (
                                <div key={subIndex+subItem.header}>
                                    <b><i>{subItem.header}</i></b>
                                    <Form>
                                        <Form.Group>
                                            <Form.Control as="textarea" value={subItem.placeHolder} onChange={subItem.onchange}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                            ))}
                        </div>}
                    </Panel>
                    <br/>
                </div>
            ))}

            <br/>

            <Panel header={"Export"}>
                <Form>
                    <Row>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Export</Form.Label>
                                <InputGroup>
                                    <Form.Control placeholder={"Character Name"} onChange={(e)=>{setCharacterName(e.target.value)}}/>
                                    <Button variant="warning" onClick={downloadJson}>Export JSON</Button>
                                    <Button variant="outline-info">
                                        <PDFDownloadLink
                                            document={createPdf()}
                                            fileName={characterName !== "" ? characterName + "_characterMisbelief.pdf" : "characterMisbelief.pdf"}>
                                            Download PDF
                                        </PDFDownloadLink>
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Import</Form.Label>
                                <Form.Control type="file" accept=".json" onChange={handleFileUpload}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Panel>
        </Container>
    )
}