import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "../../view/pages/home";
import Education from "../../view/pages/education";
import Contact from "../../view/pages/contact";
import Projects from "../../view/pages/projects";
import WritingTools from "../../view/pages/writing/writing-tools";

export function CustomRouter(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/education" element={<Education />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/writing-tools" element={<WritingTools />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </BrowserRouter>
    )
}