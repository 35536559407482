import Container from "react-bootstrap/Container";
import {Carousel, Col, Row, Image} from "react-bootstrap";
import {Panel} from "primereact/panel";
import one from "../../../assets/images/toolbox_screenshots/1.png"
import two from "../../../assets/images/toolbox_screenshots/2.png"
import three from "../../../assets/images/toolbox_screenshots/3.png"
import four from "../../../assets/images/toolbox_screenshots/4.png"
import five from "../../../assets/images/toolbox_screenshots/5.png"
import six from "../../../assets/images/toolbox_screenshots/6.png"
import seven from "../../../assets/images/toolbox_screenshots/7.png"
import eight from "../../../assets/images/toolbox_screenshots/8.png"
import flutterIcon from "../../../assets/images/flutterIcon.png"
import androidStudioIcon from "../../../assets/images/androidStudioIcon.png"

export default function MobileToolboxTab(){
    const carouselItems = [
        {
            "image": one,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": two,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": three,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": four,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": five,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": six,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": seven,
            "label": "test",
            "description": "asdf"
        },
        {
            "image": eight,
            "label": "test",
            "description": "asdf"
        },
    ]

    return(
        <Container>
            <Row>
                <Col lg={6}>
                    <Panel header="Description">
                        <p>
                            Multi-platform application that features multiple mini-apps to help me with everyday
                            tasks. Currently built for web, AndroidOS, and MacOSX.
                        </p>

                        <div style={{textAlign: "left"}}>
                            <p><b>Current mini-apps:</b></p>
                            <ul>
                                <li>QR Code Generation (for web links)</li>
                                <li>Movie/TV show streaming availability database</li>
                                <li>Vigenere cypher</li>
                                <li>Workout tracker (arms and legs categories)</li>
                            </ul>

                            <p><b>Mini-apps yet to build:</b></p>
                            <ul>
                                <li>Car locator (for large parking lots)</li>
                                <li>UPS/USPS/FedEx package tracker</li>
                                <li>Amazon price history tracker (and low-price notifier)</li>
                                <li>Remote server ping (aka liveliness probing)</li>
                            </ul>
                        </div>
                    </Panel>
                    <br/>
                    <Panel header="Project Specifications">
                        <div style={{textAlign: "left"}}>
                            <Row>
                                <Col lg={9}>
                                    <h6><u>Platform:</u> Flutter</h6>
                                    <h6><u>Programming Language:</u> Dart</h6>
                                    <h6><u>IDE:</u> Android Studio</h6>
                                    <h6><u>Optimized Platforms:</u></h6>
                                    <ul>
                                        <li>Android OS - Android API 34+</li>
                                        <li>MacOSX</li>
                                        <li>Web</li>
                                    </ul>

                                    <h6><u>Notable Packages used:</u></h6>
                                    <ul>
                                        <li><a href={"https://pub.dev/packages/riverpod"} target="_blank">riverpod</a> - for state management</li>
                                        <li><a href={"https://pub.dev/packages/yaru"} target="_blank">yaru</a> - for styling</li>
                                        <li><a href={"https://pub.dev/packages/vrouter"} target="_blank">vrouter</a> - for application routing</li>
                                        <li><a href={"https://pub.dev/packages/qr_flutter"} target="_blank">qr_flutter</a> - for QR Code generation</li>
                                        <li><a href={"https://pub.dev/packages/shared_preferences"} target="_blank">shared_preferences</a> - for memory storage</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <Image src={flutterIcon} fluid style={{width: 'auto', height: "100px"}}/>
                                    <Image src={androidStudioIcon} fluid style={{width: 'auto', height: "100px"}}/>
                                </Col>
                            </Row>

                        </div>
                    </Panel>
                </Col>
                <Col lg={6}>
                    <Panel header="Screenshots">
                        <div style={{backgroundColor: 'rgba(0,0,0,.6)'}}>
                            <Carousel fade>
                                {carouselItems.map((item, index) => (
                                    <Carousel.Item key={index}>
                                        <Image src={item.image}
                                               fluid style={{width: "auto", height: "800px"}}/>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>

                    </Panel>
                </Col>
            </Row>
        </Container>
    )
}