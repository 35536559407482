import Container from "react-bootstrap/Container";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Panel} from "primereact/panel";
import {useState} from "react";
import {Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        padding: 20,
    },
    title: {
        fontSize: 20,
        marginBottom: 20,
        marginTop: 10,
    },
    header: {
        fontSize: 16,
        marginBottom: 5
    },
    subHeader: {
        fontSize: 14,
        marginBottom: 10,
        marginTop: 5,
    },
    content: {
        fontSize: 12,
        marginBottom: 50
    },
});

export default function CharacterArcTab(){
    const [characterName, setCharacterName] = useState("");
    const [step1, setStep1] = useState("");
    const [step2, setStep2] = useState("");
    const [step3, setStep3] = useState("");
    const [step4, setStep4] = useState("");
    const [step5, setStep5] = useState("");
    const [step6, setStep6] = useState("");
    const [step7, setStep7] = useState("");
    const [step8, setStep8] = useState("");
    const [step9, setStep9] = useState("");
    const [step10, setStep10] = useState("");
    const inputs = generateInputs();


    function generateInputs(){
        let retArr = [];

        const step_1 = {};
        step_1.header = "Ask Yourself Why";
        step_1.desc = "why do you want to write this story? why is the theme important to you?"
        step_1.placeHolder = step1;
        step_1.onchange = (e) =>{setStep1(e.target.value)};

        const step_2 = {};
        step_2.header = "What Is The Protagonist's Belief?";
        step_2.desc = "(the thing they believe will make them happy)"
        step_2.placeHolder = step2;
        step_2.onchange = (e) =>{setStep2(e.target.value)};

        const step_3 = {};
        step_3.header = "What Is The Protagonist's Fear?";
        step_3.desc = "(the thing stopping them from going after what will make them happy)"
        step_3.placeHolder = step3;
        step_3.onchange = (e) =>{setStep3(e.target.value)};

        const step_4 = {};
        step_4.header = "What Is The Protagonist's Misbelief?";
        step_4.desc = "(the thing they mistakenly believe is true about the world)"
        step_4.placeHolder = step4;
        step_4.onchange = (e) =>{setStep4(e.target.value)};

        const step_5 = {};
        step_5.header = "The Opening";
        step_5.desc = "When a story first opens, nothing hooks the reader except for one thing: your protagonist's inner " +
            "conflict. In a nutshell, that means: desire VS fear. How is the protagonist's fear standing in the way of " +
            "getting what they think will make them truly happy?"
        step_5.placeHolder = step5;
        step_5.onchange = (e) =>{setStep5(e.target.value)};

        const step_6 = {};
        step_6.header = "The Inciting Incident";
        step_6.desc = "Why does this incident incident matter to your protagonist? How does it push her outside her " +
            "comfort zone? How is she going to respond to it, based on the fear that has raised her?"
        step_6.placeHolder = step6;
        step_6.onchange = (e) =>{setStep6(e.target.value)};

        const step_7 = {};
        step_7.header = "The Middle";
        step_7.desc = "The fear-based decision your protagonist made after the inciting incident is something she's " +
            "still paying for...until the game-changing midpoint. This usually leads to bad decisions on the " +
            "protagonist's part, as she THINKS she's doing teh right thing, but is actually doing just the opposite " +
            "(still trying to avoid the thing she's afraid of.)"
        step_7.placeHolder = step7;
        step_7.onchange = (e) =>{setStep7(e.target.value)};

        const step_8 = {};
        step_8.header = "The Dark Moment";
        step_8.desc = "Disaster Strikes. But what does this disaster personally mean to the protagonist? How does it " +
            "force them to realize that they're the one to blame for this crisis? How does it completely disarm them " +
            "and make them face off with their fear and misbelief?"
        step_8.placeHolder = step8;
        step_8.onchange = (e) =>{setStep8(e.target.value)};

        const step_9 = {};
        step_9.header = "The \"Aha!\" Moment";
        step_9.desc = "After your protagonist has been brought to their knees by the disaster, they have a revelation " +
            "— an aha moment. They can suddenly see how their fear and misbelief has led them to make the wrong " +
            "decisions about everything. So how is your protagonist going to overcome their fear and continue to the " +
            "climax, therefore developing as a character? What lesson are they going to learn (and simultaneously " +
            "teach the audience?)"
        step_9.placeHolder = step9;
        step_9.onchange = (e) =>{setStep9(e.target.value)};

        const step_10 = {};
        step_10.header = "The Ending";
        step_10.desc = "Protagonist faces their most difficult challenge yet. It's a true test of their character — " +
            "and how they respond to the situation is the proof that they've changed. At the end of the story, your " +
            "reader should feel contented with the message of the book because the character HAS changed for the " +
            "better, even if she wasn't able to make things right in the end."
        step_10.placeHolder = step10;
        step_10.onchange = (e) =>{setStep10(e.target.value)};


        retArr.push(step_1);
        retArr.push(step_2);
        retArr.push(step_3);
        retArr.push(step_4);
        retArr.push(step_5);
        retArr.push(step_6);
        retArr.push(step_7);
        retArr.push(step_8);
        retArr.push(step_9);
        retArr.push(step_10);

        return retArr;
    }


    const createPdf = () => (
        <Document>
            <Page style={styles.page}>
                <View>
                    <Text style={styles.title}>Character Arc Worksheet - {characterName}</Text>
                    {inputs.map((item, index) => (
                        <div key={"characterArc"+index}>
                            <Text style={styles.header}>{item.header}</Text>
                            <Text style={styles.subHeader}>{item.desc}</Text>
                            <Text style={styles.content}>{item.placeHolder}</Text>
                        </div>
                    ))}
                </View>
            </Page>
        </Document>
    )

    function createExportJson() {
        let json = {};

        inputs.forEach((item, index) => {
            const key = "step"+(index+1);
            json[key] = {};
            json[key].header = item.header;
            json[key].content = item.placeHolder;
        })

        return json;
    }

    const downloadJson = () => {
        const jsonBlob = new Blob([JSON.stringify(createExportJson(), null, 2)], { type: 'application/json' });
        const url = URL.createObjectURL(jsonBlob);

        const link = document.createElement('a');
        link.href = url;
        link.download = characterName !== "" ? characterName + "_characterArc": "characterArc";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    function importJson(jsonData){
        for (const key in jsonData){
            let method = "set" + key[0].toUpperCase() + key.substring(1);
            let value = jsonData[key].content;

            const setCall = `${method}("${value}")`;
            try{
                eval(setCall)
            }catch (e) {
            }
        }
    }

    const handleFileUpload = (event) => {
        const file = event.target.files[0]; // Get the selected file
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result); // Parse JSON data
                importJson(jsonData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        };

        reader.readAsText(file); // Read the file as text
    };

    return(
        <Container>
            {inputs.map((item, index) => (
                <div key={index+"characterMisbelief"}>
                    <Panel header={"Step " +(index+1)}>
                        <h5>{item.header}</h5>
                        <p><i>{item.desc}</i></p>
                        <Form.Group>
                            <Form.Control as="textarea" value={item.placeHolder} onChange={item.onchange}/>
                        </Form.Group>
                    </Panel>
                    <br/>
                </div>
            ))}

            <br/>

            <Panel header={"Export"}>
                <Form>
                    <Row>
                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Export</Form.Label>
                                <InputGroup>
                                    <Form.Control placeholder={"Character Name"} onChange={(e)=>{setCharacterName(e.target.value)}}/>
                                    <Button variant="warning" onClick={downloadJson}>Export JSON</Button>
                                    <Button variant="outline-info">
                                        <PDFDownloadLink
                                            document={createPdf()}
                                            fileName={characterName !== "" ? characterName + "_characterArc.pdf" : "characterArc.pdf"}>
                                            Download PDF
                                        </PDFDownloadLink>
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>

                        <Col lg={6}>
                            <Form.Group>
                                <Form.Label>Import</Form.Label>
                                <Form.Control type="file" accept=".json" onChange={handleFileUpload}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Panel>
        </Container>
    )
}